





























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Projects } from '@/store';
import { Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import Utils from '@/modules/Utils';

@Component({
    components: {}
})
export default class EditUserMode extends Vue {
    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;
    @Projects.State('list') projects;

    @Prop() user;
    @Prop() dialog_type;
    @Prop() project_name_by_id;
    @Prop() mobile_roles;
    @Prop() mobile_roles_options;

    user_details = null;
    loading = true;
    utils_module = Utils;
    password = '';
    confirm_password = '';

    mounted(){
        this.user_details = cloneDeep(this.user);
        this.user_details.mobile = this.user_details.mobile.filter((option) => this.mobile_roles_options.find((mobile_option) => mobile_option.value === option) !== undefined);
        this.loading = false;
    }

    checkPassword(){
        if (!this.password) {
            return this.$t('Required');
        }
        const match1 = this.password.match(/[a-z]/i);
        if (match1 === null || typeof match1 !== 'object') {
            return this.$t('Password should contain at least 1 lowercase');
        }

        const match2 = this.password.match(/[0-9]/);
        if (match2 === null || typeof match2 !== 'object') {
            return this.$t('Password should contain at least 1 number');
        }

        const match3 = this.password.match(/[_!@#$%^&*"]/);
        if (match3 === null || typeof match3 !== 'object') {
            return `${this.$t('Password should contain at least one of the signs')} _!@#$%^&*"`;
        }

        const match4 = this.password.match(/[A-Z]/);
        if (match4 === null || typeof match4 !== 'object') {
            return this.$t('Password should contain at least 1 uppercase');
        }
        
        const match5 = Utils.checkContainsHebrewLetters(this.password);
        if (match5 !== null && typeof match5 === 'object'){
            return `${this.$t('Password should contain only the characters')} [A-Z][a-z][1-9][_!@#$%^&*"]`;
        }

        return true;
    }

    checkConfirmPassword() {
        if (!this.confirm_password) {
            return this.$t('Required');
        }

        if (this.password !== this.confirm_password) {
            return this.$t('The password and the confirm password does not match');
        }
        return true;
    }

    checkFirstName(){
        if (!/^[a-zA-Z]+$/.test(this.user_details.first_name)){
            return `${this.$t('First name must contains only the characters')} [a-z][A-Z]`;
        }

        return true;
    }

    checkLastName(){
        if (!/^[a-zA-Z]+$/.test(this.user_details.last_name)){
            return `${this.$t('Last name must contains only the characters')} [a-z][A-Z]`;
        }

        return true;
    }

    checkEmail(){
        const has_hebrew_letters = Utils.checkContainsHebrewLetters(this.user_details.email);
        if (has_hebrew_letters !== null && typeof has_hebrew_letters === 'object'){
            return this.$t(`Email can't contain hebrew letters`);
        }

        const has_match = Utils.isValidEmailAddress(this.user_details.email);
        if (has_match === null || typeof has_match !== 'object') {
            return this.$t('Invalid email address');
        }

        return true;
    }

    get projects_list(){
        return [{text: this.$t('All'), value: 'all'}].concat(this.projects.map((project) => ({text: project.name, value: project.id})));
    }

    get user_roles(){
        return [
            {text: this.$t('Project User'), value: 'PU'},
            {text: this.$t('Readonly User'), value: 'PR'},
            {text: this.$t('Admin'), value: 'CM'}
        ];
    }

    get notifications_severity_options(){
        return [
            {text: this.$t('High'), value: 'high'},
            {text: this.$t('Medium'), value: 'medium'},
            {text: this.$t('Low'), value: 'low'}
        ];
    }

    validateUserPhoneNumber(){
        if (!this.user_details.phone_number) {
            return this.$t('Required');
        }

        if (isNaN(this.user_details.phone_number.slice(1, this.user_details.phone_number.length)) || this.user_details.phone_number[0] !== '+') {
            return this.lang === 'en' 
            ? `Phone number must include only digits and intl. code, Example +972000000000`
            : `${this.$t('Phone number must include only digits and intl')}, ${this.$t('Example')} +972000000000`;
        }

        if (this.user_details.phone_number.length < 12) {
            return this.$t('Phone number is too short');
        }

        return true;
    }

    updateProjectsList(new_role) {
        if (new_role === 'CA' || new_role === 'CM') {
            this.user_details.projects = ['all'].concat(this.projects.map((project) => project.id));
        }else if ((new_role === 'PR' || new_role === 'PU') && (this.user_details.role === 'CA' || this.user_details.role === 'CM')) {
            this.user_details.projects = [];
        }
        this.user_details.role = new_role;
    }

    handleSelectionChanged(options, selected_list_name, new_value){
        const prev_value = this.user_details[selected_list_name];
        if (new_value.length && new_value.slice(-1)[0] === 'all') {
            this.user_details[selected_list_name] = options.map((option) => option.value);
        }else if (new_value.length !== options.length){
            if (!new_value.includes('all') && prev_value.includes('all')) {
                this.user_details[selected_list_name] = [];
            }else if (prev_value.length + 1 === new_value.length && new_value.length + 1 === options.length){
                this.user_details[selected_list_name] = options.map((option) => option.value);
            }else if (prev_value.includes('all') && new_value.includes('all') && new_value.length < prev_value.length){
                this.user_details[selected_list_name] = new_value.filter((option) => option !== 'all');
            }else {
                this.user_details[selected_list_name] = new_value;
            }
        }
    }
}

